import Link from '../styles/ActiveLink'
import * as S from './Nav.styles'
import { useRouter } from 'next/router'
import { useState } from 'react';

export default function Nav() {
  const [active, setActive] = useState(false);
  function toggle() {
    setActive(!active);
  }
  return (
    <>
      <S.NavButton active={active} toggle={toggle} />
      <S.Nav active={active}>
        <ul>
          <li>
            <Link href="/" toggle={toggle}>
                🏠 <span className='underline'>Home</span>
            </Link>
          </li>
          <li>
            <Link href="/story" toggle={toggle}>
                📖 <span className='underline'>Story</span>
            </Link>
          </li>
          <li>
            <Link href="/venue" toggle={toggle}>
                📍 <span className='underline'>Venue &amp; Visit</span>
            </Link>
          </li>
          <li>
            <Link href="/travel" toggle={toggle}>
                ✈️ <span className='underline'>Travel</span>
            </Link>
          </li>
          <li>
            <Link href="/photos" toggle={toggle}>
                📸 <span className='underline'>Photos</span>
            </Link>
          </li>
          <li>
            <a href="https://open.spotify.com/playlist/6R7c4LrOvXDBiGus1NWA5E?si=z-G77nkWTqi9KGQSTxiu8A" target="_blank" rel="noopener noreferrer">
              🎶 <span className='underline'>Playlist</span>
            </a>
          </li>
        </ul>
      </S.Nav>
    </>
  )
}