import * as S from './Toggle.styles'
import { useEffect, useState } from 'react'

function handleEnterPress(e, cb) {
  if (e.charCode === 13) {
    cb();
  }
};

export default function Toggle({ isChecked, action, label }) {
  const [checked, setChecked] = useState(isChecked)
  useEffect(()=> {
    setChecked(isChecked)
  }, [isChecked])
  
  return (
    <S.LabelGroup>
      <S.Switch>
        <S.Checkbox id="modeCheckbox" onClick={action} onKeyPress={(e) => handleEnterPress(e, action)} checked={checked} tabindex="-1" readOnly />
        <S.Slider checked={checked}></S.Slider>
      </S.Switch>
      <S.Label htmlFor="modeCheckbox" checked={checked}>
        {label}
      </S.Label>
    </S.LabelGroup>
  )
}
