import styled, { keyframes } from 'styled-components';

export const LabelGroup = styled.div`
  display: flex;
  align-items: center;
`

/* The switch - the box around the slider */
export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 3.4rem;

  /* Hide default HTML checkbox */
  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

/* The slider */
export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 3.4rem;

  &::before {
    position: absolute;
    content: "";
    height: 2.6rem;
    width: 2.6rem;
    left: 0.4rem;
    bottom: 0.4rem;
    background-color: var(--bg);
    transition: .4s;
    border-radius: 50%;
  }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  &:checked + ${Slider} {
    background-color: var(--secondary);
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1rem var(--secondary-dark);
  }

  &:checked + ${Slider}:before {
    transform: translateX(2.6rem);
  }
`

export const Label = styled.label`
  font-size: 1.5rem;
  position: relative;
  left: ${props => props.checked ? "-2.6rem" : "-5.3rem" };
  top: -0.1rem;
  opactiy: 0;
  transition: .4s;
  user-select: none;
  font-size: 1.6rem;
`