const size = {
  // mobileS: '320px',
  mobile: '375px',
  // mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  // laptopL: '1440px',
  // desktop: '2560px'
}



export const device = {
  // mobileS: `(min-width: ${size.mobileS})`,
  mobile: `only screen and (max-width: ${size.mobile})`,
  // mobileL: `(min-width: ${size.mobileL})`,
  tablet: `only screen and (max-width: ${size.tablet})`,
  laptop: `only screen and (max-width: ${size.laptop})`,
  // laptopL: `(min-width: ${size.laptopL})`,
  // desktop: `(min-width: ${size.desktop})`,
  // desktopL: `(min-width: ${size.desktop})`
};

export default device;
