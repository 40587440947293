import PropTypes from 'prop-types';
import Footer from '../Footer';
import * as H from '../styles/Headings';
import * as S from './Page.styles';
import Nav from '../Nav';
import Link from 'next/link';
import usePersistedState from '../../hooks/usePersistedState';
import Head from 'next/head';
import Header from '../Header';
import { useEffect, useState } from 'react';

function setCookie(cName, cValue) {
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

export default function Page({ children, cookie }) {
  const defaultMode = cookie
    ?.split(';')
    ?.find(c => c.trim().startsWith('displayMode=dark'))
    ? 'dark' : 'light';

  const [mode, setMode] = useState(defaultMode)

  const setPersistedMode = (newMode) => {
    if (newMode === 'dark') {
      document.cookie = `displayMode=${newMode}`
    } else {
      document.cookie = document.cookie
        ?.split(';')
        ?.find(c => c.trim().startsWith('displayMode='))
      ? 'displayMode=' : document.cookie
    }
    setMode(newMode)
  }

  return (
    <>
    <Head>
      <title>David &amp; Chloë</title>
      <link rel="shortcut icon" href="static/favicon.svg" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="apple-touch-icon" href="/images/DC w Heart 192.jpg"></link>
      <meta property="og:title" content="David &amp; Chloë" />
      <meta property="og:site_name" content="David &amp; Chloë" />
      <meta property="og:image" content="https://davidchloe.com/images/DC.jpg" />
      <meta property="og:url" content="https://davidchloe.com" />
      <meta property="og:type" content="website" />
      <meta property="og:updated_time" content="1619145544" />
      <meta name="theme-color" content="hsl(38, 45%, 39%)" />
    </Head>
      <S.GlobalStyles mode={mode} />
      <S.InnerStyles>
        <Header mode={mode} setMode={setPersistedMode}/>
        <Nav />
        <S.Main>
          <H.H1>
          👰‍♀️Chloë&nbsp;&amp;&nbsp;David🤵
          </H.H1>
          <S.Subtitle>
            <strong>
              🌍 Harrogate, UK
            </strong>
            <strong>
              📅 4<sup>th</sup> September 2022
            </strong>
          </S.Subtitle>
          {children}
        </S.Main>
        <Footer />
      </S.InnerStyles>
    </>
  );
}

Page.propTypes = {
  children: PropTypes.any,
};
