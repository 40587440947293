import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { cloneElement, Children, forwardRef } from 'react'
import styled from 'styled-components'

const A = styled.a`
  display: inline-block;
  margin: 0 1.5rem;
  color: var(--primary);
  text-decoration: none;
  text-align: center;
`

const ActiveLink = ({ children, ...props }) => {
  const { asPath } = useRouter()
  const child = Children.only(<span>{children}</span>)
  const childClassName = child.props.className || ''

  const className =
    asPath === props.href || asPath === props.as
      ? `${childClassName} active`.trim()
      : childClassName

  return (
    <Link {...props} passHref legacyBehavior>
      <A onClick={props.toggle}>
        {cloneElement(child, {
          className: className || null,
        })}
      </A>
    </Link>
  );
}

export default ActiveLink
