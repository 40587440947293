import styled, { keyframes } from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const growNShrink = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
`

export const Emoji = styled.span`
  margin: 2rem;
  font-size: 5rem;
  cursor: pointer;
  width: 5rem;

  &:hover {
    transform-origin: left center;
    animation: ${growNShrink} .5s alternate infinite;
  }
`;
