import Link from 'next/link';
import * as S from './Header.styles';
import Toggle from '../atoms/Toggle'
import { useState } from 'react';

export default function Header({ mode, setMode }) {
  function toggleMode() {
    if (mode === 'light') {
      setMode('dark')
    } else {
      setMode('light')
    }
  }

  return (
    <S.Header>
      <Link href="/" passHref legacyBehavior>
        <S.Emoji>❤️</S.Emoji> 
      </Link>
      <Toggle isChecked={mode === 'light'} action={toggleMode} label={mode === 'light' ? '☀️' : '🌙'} tabindex="0"/>
    </S.Header>
  );
}
