import styled, { createGlobalStyle } from 'styled-components';
import device from '../styles/device';
import nProgress from '../styles/nprogress';
import { normalize } from 'styled-normalize';

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  :root {
    --bg: ${props => props.mode === 'light' ? "#fff" : "#111"};
    --light: ${props => props.mode === 'light' ? "#eee" : "#333"};
    --primary: ${props => props.mode === 'light' ? "#000" : "#fff"};
    --secondary: ${props => props.mode === 'light' ? "hsl(38, 51%, 39%)" : "hsl(38, 51%, 53%)"};
    --secondary-dark: ${props => props.mode === 'light' ? "hsl(38, 34%, 33%)" : "hsl(38, 51%, 47%)"};
    --tertiary: ${props => props.mode === 'light' ? "#333" : "#ddd"};

    font-size: 62.5%;
    scrollbar-color: var(--secondary);
  }

  html,
  body {
    background-color: var(--bg);
    color: var(--primary);
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
  }

  * {
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--light);

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: var(--light);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--secondary);
      border-radius: 20px;
      border: 3px solid var(--light);
    }
  }

  ${nProgress}
`;

export const InnerStyles = styled.div`
  display: flex;
  padding: 0 1rem;
  min-height: 100vh;
  flex-direction: column;
`;

export const Subtitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  strong {
    margin: 0;
    padding: 0 0 5rem 0;
    text-align: center;
    line-height: 1;
    font-size: 2rem;
    font-family: 'Futurist-Fixed-width', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    letter-spacing: -.35rem;
    text-transform: uppercase;
    color: var(--secondary-dark);

    @media ${device.mobile} {
      font-size: 1.5rem;
    }
  }
`

export const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  a,
  a:link,
  a:visited {
    color: var(--secondary);
  }
  a:hover {
    background-color: var(--light)
  }
`
