import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  border-top: 1px solid var(--light);
  width: 100%;
  height: 7rem;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  z-index: 100;
  background-color: var(--bg);
  margin-top: 2rem;

  a {
    display: inline-block;
    color: var(--secondary-dark);
    text-decoration: none;
    font-weight: 400;
    justify-content: center;
    align-items: center;

    :hover {
      font-weight: 600;
    }

    ::before {
      display: block;
      text-align: center;
      content: 'David Sint';
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
`
