import styled from 'styled-components';
import device from '../styles/device'

export const Nav = styled.nav`
  & ul {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 2rem;

    & li a {
      display: inline-block;
      margin: 0 1.5rem;
      color: var(--primary);
      transition: color .4s;
      text-decoration: none;
      text-align: center;
      font-family: 'Futurist-Fixed-width', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      letter-spacing: -.35rem;
      text-transform: uppercase;

      &:hover .underline {
        color: var(--secondary);
        text-decoration: underline;
        text-underline-offset: 0.35rem;
        cursor: pointer;
      }
      &.active {
        color: var(--secondary);
      }
    }
  }
  @media ${device.tablet} {
    display: ${props => props.active ? 'flex' : 'none'};
    & ul {
      flex-direction: column;
      align-items: center;
      margin: 0.3rem auto;
      & li a {
        margin: 0.5rem 0;
      }
    }
  }
`

const ButtonContainer = styled.button`
  display: none;
  @media ${device.tablet} {
    display: block;
    background-color: var(--dark);
    border: 0.1rem solid var(--secondary);
    margin: 0.2rem auto;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    max-width: 5.1rem;
    .bar1,
    .bar2,
    .bar3 {
      width: 2rem;
      height: 0.2rem;
      background-color: var(--secondary);
      margin: 0.5rem 0.5rem;
      transition: 0.25s;
    }
    .active.bar1 {
      transform: rotate(45deg) translate(5px, 5px);
    }
    .active.bar2 {
      opacity: 0;
    }
    .active.bar3 {
      transform: rotate(-45deg) translate(5px, -5px);
    }
    &:hover{
      border: 0.2rem solid var(--secondary);
      margin: 0.1rem auto;
      max-width: 5.2rem;
    }
  }
`;

export function NavButton({active, toggle}) {
  return (
    <ButtonContainer onClick={toggle} aria-label="Navigation">
      <div className={active ? "active bar1" : "bar1"} />
      <div className={active ? "active bar2" : "bar2"} />
      <div className={active ? "active bar3" : "bar3"} />
    </ButtonContainer>
  );
}
