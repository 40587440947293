import styled from 'styled-components'
import device from './device'


export const H1 = styled.h1`
  margin: 0;
  padding: 5rem 0 1rem 0;
  text-align: center;
  line-height: 1;
  font-size: 8vw;
  font-family: 'asterism', 'Apple Chancery', 'Bradley Hand', 'Brush Script MT', cursive;
  color: var(--secondary-dark);

  @media ${device.tablet} {
    font-size: 11vw;
  }

  @media ${device.mobile} {
    font-size: 14vw;
  }
`

export const H2 = styled.h1`
  margin: 0;
  padding: 1rem 0 0.1rem 0;
  text-align: center;
  line-height: 1;
  font-size: 4vw;

  @media ${device.mobile} {
    font-size: 6vw;
  }
`

export const H3 = styled.h1`
  margin: 0;
  padding: 1rem 0 0.1rem 0;
  text-align: center;
  line-height: 1;
  font-size: 2rem;

  @media ${device.mobile} {
    font-size: 2rem;
  }
`

export const H4 = styled.h4`
  margin: 0;
  padding: 0 0 5rem 0;
  text-align: center;
  line-height: 1;
  font-size: 2rem;
  font-family: 'Futurist-Fixed-width', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  letter-spacing: -.35rem;
  text-transform: uppercase;

  @media ${device.mobile} {
    font-size: 1.5rem;
  }
`
