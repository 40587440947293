import { useEffect, useState } from 'react'

export default function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(
    () => (typeof window !== 'undefined' ? JSON.parse(localStorage.getItem(key)) : null) || defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}
